import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";

export const getPacks = createAsyncThunk("packs/getAll", () => {
  return axios.get("/pack/public").then((response) => response.data);
});

export const deletePack = createAsyncThunk("packs/delete", (id) => {
  return axios.delete(`/pack/${id}`).then((response) => response.data);
});

export const editPack = createAsyncThunk("packs/edit", (payload) => {
  const { id, data } = payload;
  return axios.patch(`/pack/${id}`, data);
});

export const addPack = createAsyncThunk("packs/add", (payload) => {
  return axios.post("/pack/create", payload);
});

const packsAdapter = createEntityAdapter({
  selectId: (pack) => pack._id,
});

export const { selectAll: selectPacks, selectById: selectPacksById } =
  packsAdapter.getSelectors((state) => state.packs);

const packsSlice = createSlice({
  name: "packs",
  initialState: packsAdapter.getInitialState({}),
  reducers: {},
  extraReducers: {
    [getPacks.pending](state) {},
    [getPacks.fulfilled](state, { payload }) {
      packsAdapter.setAll(state, payload);
    },
    [getPacks.rejected](state) {},
  },
});

export default packsSlice.reducer;
